import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import RelatedSkin from "../components/RelatedSkin";
import { getData } from "../helper/helper";
import DealForm from "../components/DealForm";

// eslint-disable-next-line
export const SkinCareTemplate = ({
  content,
  contentComponent,
  title,
  locale,
  category,
  featuredimage,
  helmet,
  deal,
}) => {
  const PostContent = contentComponent || Content;
  const body = getData(locale).skinPage;

  return (
    <section className="subPage ">
      {helmet || ""}
      <div className="image">
        <Img
          fluid={featuredimage.childImageSharp.fluid}
          alt={title}
          title={title}
          className="post-image"
        />
        <div className="header-titles">
          <p>{category}</p>
          <h1>{title}</h1>
        </div>
      </div>
      <div className="container content">
        <div className="main">
          <div className="content">
            <PostContent content={content} />
            {deal && deal.title && (
              <DealForm
                title={deal.title}
                description={deal.description}
                sendfrom={title}
              />
            )}
          </div>
        </div>
        <div className="aside">
        </div>
      </div>
      <div className="relatedPosts">
        <h5>{body.related}</h5>
        <div className="container services">
          <RelatedSkin />
        </div>
      </div>
    </section>
  );
};

SkinCareTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  locale: PropTypes.string,
  description: PropTypes.string,
  category: PropTypes.string,
  featuredpost: PropTypes.string,
  featuredimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  helmet: PropTypes.object,
  deal: PropTypes.object,
  metatitle: PropTypes.string,
};

const SkinPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout locale={post.frontmatter.locale}>
      <SkinCareTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet>
            <title>{`${
              post.frontmatter.metatitle
                ? post.frontmatter.metatitle
                : post.frontmatter.title
            }`}</title>
            {post.frontmatter.metatitle && (
              <meta name="title" content={`${post.frontmatter.metatitle}`} />
            )}
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        featuredimage={post.frontmatter.featuredimage}
        category={post.frontmatter.category}
        locale={post.frontmatter.locale}
        deal={post.frontmatter.deal}
      />
    </Layout>
  );
};

SkinPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default SkinPost;

export const pageQuery = graphql`
  query SkinPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        metatitle
        description
        locale
        category
        featuredpost
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 1600, quality: 75) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
